.videoContainer {
	width: 100%;
	height: 100%;
	background: black;
	background-clip: padding-box;
	border: solid 13px transparent;
	border-radius: 1em;
}

.videoBackdrop {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background: linear-gradient(#00ffd5, #1aa6db);
}

.nrkVideoContainer {
	height: fit-content;
}