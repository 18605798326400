@import url(https://fonts.googleapis.com/css?family=Muli);
.videoContainer {
	width: 100%;
	height: 100%;
	background: black;
	background-clip: padding-box;
	border: solid 13px transparent;
	border-radius: 1em;
}

.videoBackdrop {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background: linear-gradient(#00ffd5, #1aa6db);
}

.nrkVideoContainer {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}
.backgroundImage {
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: right;
	height: 100%;
	width: 100%;
}

.stepBackground {
	background-image: url(/static/media/background.4a95d53c.svg);
}

.stepBackdrop {
	background-image: url(/static/media/group-2.e8fd0b50.svg);
}

.paperclip {
	background-image: url(/static/media/paperclip2.34a2af23.svg);
	position: absolute;
	top: -2vmax;
	left: 3vmax;
	background-repeat: no-repeat;
	background-size: contain;
	height: 7vmax;
	width: 5vmax;
	opacity: 0.7;
}
/* Provide sufficient contrast against white background */

a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

body,
html {
	margin: 0;
	padding: 0;
}
* {
	box-sizing: border-box;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

