.backgroundImage {
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: right;
	height: 100%;
	width: 100%;
}

.stepBackground {
	background-image: url("../img/background.svg");
}

.stepBackdrop {
	background-image: url("../img/group-2.svg");
}

.paperclip {
	background-image: url("../img/paperclip2.svg");
	position: absolute;
	top: -2vmax;
	left: 3vmax;
	background-repeat: no-repeat;
	background-size: contain;
	height: 7vmax;
	width: 5vmax;
	opacity: 0.7;
}