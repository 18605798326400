/* Provide sufficient contrast against white background */
@import url("https://fonts.googleapis.com/css?family=Muli");

a {
	color: #0366d6;
}

code {
	color: #e01a76;
}

body,
html {
	margin: 0;
	padding: 0;
}
* {
	box-sizing: border-box;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}
